import { styled } from 'styled-components'

export const StyledContainerDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;

  @media (min-width: 768px) {
    gap: 24px;
  }

  @media (min-width: 1440px) {
    gap: 40px;
  }
`

export const StyledGalleryHeading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
`
export const StyledGHDropdownContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  flex: 1 0 0;

  @media (max-width: 767px) {
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
`
export const StyledOverlayContainer = styled.div`
  position: absolute;
  top: 65px;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.7);

  @media (max-width: 767px) {
     top: 160px;
  }
`;

export const StyledOverlayContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5% 20%;
  gap: 24px;
`;

export const StyledOverlayButton = styled.button`
  height: 33px;
  width: 110px;
  padding: 8px 16px;
  background: rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(226, 226, 226, 1);
  border-radius: 40px;
  color: white;
  margin: 0 auto;

  &:hover {
    background: linear-gradient(248.24deg, #FFFFFF 17.66%, #C6C6C6 71.37%);
    color: black;
    cursor: pointer;
  }

`;
